import React, {
  createContext, useCallback, useState, useContext, ReactNode,
} from 'react';
import PropTypes from 'prop-types';

// import { authEmail } from '../Firebase/authLogin';
type AuthContextProviderProps = {
  children?: ReactNode | undefined;
}
interface IAuthContext {
  signed: boolean;
  login(email: string, password: string): void;
  logout(): void;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const AuthProvider = ({ children }: AuthContextProviderProps) => {
  const [signed, setSigned] = useState<boolean>(() => {
    const token = localStorage.getItem('@weconnect:signed');

    if (token) {
      return true;
    }
    return false;
  });

  const login = useCallback(async (email: string, password: string) => {
    // const response = await authEmail(email, password);
    if (email === 'admin@meamo.com' && password === 'admin1234') {
      localStorage.setItem('@weconnect:signed', 'contato@wcedu.com.br');
      setSigned(true);
    }
  }, []);

  const logout = () => {
    localStorage.removeItem('@weconnect:signed');
    setSigned(false);
  };

  return (
    <AuthContext.Provider value={{ signed, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContext {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthProvider, useAuth };
