import React from 'react';

import Sidebar from '../../components/sidebar';
import {
  Container,
  Page,
} from './styles';

// interface Repository {
//     full_name: string;
//     description: string;
//     owner: {
//         login: string;
//         avatar_url: string;
//     };
// }
const Dashboard: React.FC = () => (
  <>

    <Container className="wrapper">
      <Sidebar />
      <Page className="content">
        <h1>Building ... </h1>
      </Page>
    </Container>
  </>
);

export default Dashboard;
