import styled from 'styled-components';

export const Container = styled.div`
    /* display: block;
    background-color: #2b3040;
    color: #a8a8b3;
    max-width: 20%;
    max-height: 100%;
    padding-left: 2%;
    padding-bottom: 100%;
    button {
        background-color: #2b3040;
        margin-top: 7%;
        margin-left: 77%;
        border: none;
        color: #fff;
        transition: color 0.2s;
        &:hover {
            color: #666;
        }
    }
    a {
        display: block;
        align-items: center;
        justify-content: center;
        margin: 7%;
        text-decoration-line: none;
        color: #8d95ae;
        transition: color 0.2s;


        &:hover {
            color: #666;
        }
        svg {
            margin-right: 7px;
        }
    } */
`;

export const Header = styled.div`
    h1 {       
        padding: 18%;
        color: #fff;
    }
`;
export const Img = styled.img`
    margin-bottom: 33%;
    width: 120px;
`;
export const MenuSidebar = styled.div`
  display: block;
  padding: 0 20px;
  a {
    color: #ffffff;
    display: block;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 15px;
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    svg {
      margin-right: 16px;
    }
    &:hover {
      background-color: var(--primary600);
    }
  }
`;
