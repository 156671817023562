import React from 'react';
import { Link } from 'react-router-dom';
import {
  AiOutlineHome,
  AiOutlineTeam,
  AiTwotoneCamera,
  AiOutlineLogout,
} from 'react-icons/ai';
import { MdProductionQuantityLimits, MdOutlineCategory } from 'react-icons/md';
import { FaMap, FaUsersCog } from 'react-icons/fa';
import { CiShoppingTag } from 'react-icons/ci';
// import { RiShoppingBag3Line } from 'react-icons/ri';

import {
  Container,
  Header,
  MenuSidebar,
  Img,
} from './styles';
import { useAuth } from '../../hooks/auth';
import banner from '../../assets/logo.png';

const Dashboard: React.FC = () => {
  const { logout } = useAuth();
  const logOut = () => {
    logout();
  };

  return (
    <Container className="sidebar">
      <button
        type="button"
        onClick={() => logOut()}
      >
        <AiOutlineLogout size={19} />
      </button>
      <Header>
        <Img src={banner} />

      </Header>

      <div><h2>Admin</h2></div>
      <MenuSidebar>
        <Link to="/">
          <AiOutlineHome size={20} />
          Dashboard

        </Link>
        <Link to="/products">
          <MdProductionQuantityLimits size={20} />
          Produtos
        </Link>
        <Link to="/users">
          <AiOutlineTeam size={20} />
          Usuarios
        </Link>
        <Link to="/looks">
          <AiTwotoneCamera size={20} />
          Looks
        </Link>
        <Link to="/categories">
          <MdOutlineCategory size={20} />
          Categorias
        </Link>
        <Link to="/pots">
          <FaMap size={20} />
          Vitrines
        </Link>
        <Link to="/pots">
          <FaUsersCog size={20} />
          Funcionários
        </Link>
        <Link to="/pots">
          <CiShoppingTag size={20} />
          Ofertas
        </Link>
        {/* <Link to="/malls">
        <RiShoppingBag3Line size={20} />
        Shoppings
      </Link>
      <Link to="/stores">
        <FaStore size={20} />
        Lojas
      </Link>
      <Link to="/products">
        <AiOutlineShoppingCart size={20} />
        Produtos
      </Link> */}
      </MenuSidebar>

    </Container>
  );
};

export default Dashboard;
