import styled from 'styled-components';

interface Props {
  isDragActive?: any;
  dragActive?: any;
  dragReject?: any;
  isDragReject?: any;
}
interface Type {
  type?: 'default' | 'error' | 'success';
}

export const Container = styled.div`
  display: flex;
  div {
    width: 100%;
  }
`;
export const Page = styled.div`
  width: 100%;
`;
export const Title = styled.h3`
  color: #868b9b;
  font-size: 30px;
  font-weight: bolder;
  padding: 3%;
`;
export const PaginateBar = styled.div``;

export const AddMall = styled.div`
  justify-content: center;
  align-items: center;

  background-color: #fff;
  padding: 1%;
  input {
    border-radius: 7px;
    border: 2px solid #f0f0f0;
    width: 77%;
    margin-left: 10%;
    height: 27px;
    padding-left: 30%;
    margin-bottom: 0;
  }
  div {
    display: flex;
    margin-left: 4px;
  }
  label {
    margin: 1%;
  }
  form {
    display: flex;
    width: 100%;
    div {
      display: block;
      input {
        border-radius: 7px;
        border: 2px solid #f0f0f0;
        padding-left: 10%;
        width: 100%;
        margin-left: 0%;
      }
    }
  }
  button {
    margin-left: 40%;
  }
`;

export const MapStyle = styled.div`
  margin: 2px;
`;

export const ListUsers = styled.div`
  margin-top: 3%;
  background-color: #f3f3f7;
`;
export const RepositoriesInfo = styled.div`
  width: 100%;
  padding: 2%;
  table {
    width: 100%;
    thead {
      tr {
        th {
          color: #f3f3f7;
          font-family: 'Roboto', sans-serif;
          font-size: 12px;
          & + th {
            background-color: #fff;
            border: 1px solid #f0f0f0;
            color: #151715;
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding-left: 8px;
          padding-right: 1px;
          margin-right: 0px;
          height: 100%;
          max-width: 5px;
          background-color: #f3f3f7;
          button {
            margin-left: 27%;
            border: none;
            background-color: #fff;
            & + button {
              margin-left: 5%;
            }
          }
          & + td {
            padding: 1px;
            height: 32px;
            margin: 0;
            max-width: none;
            padding: 1px;
            background-color: #fff;
            border: 1px solid #f0f0f0;
            font-size: 12px;
          }
        }
      }
    }
  }
`;

export const Logo = styled.img`
  max-width: 45%;
  margin-left: 7px;
  height: 2%;
  border-radius: 50%;
`;
