import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
// import { FiTrash2, FiSearch } from 'react-icons/fi';
import {
  AiOutlineDelete,
  // AiOutlineFolderView,
  AiOutlineSearch,
  AiFillPlusCircle,
  AiFillCamera,
  AiFillDelete,
} from 'react-icons/ai';
import { MdOutlinePhotoSizeSelectLarge, MdPhotoSizeSelectActual } from 'react-icons/md';
import api from '../../services/api';
import Sidebar from '../../components/sidebar';
import {
  Container,
  DivColumnFlexSample,
  Page,
  Title,
  ListUsers,
  RepositoriesInfo,
  PaginateBar,
  BtnNewCat,
  InputContainer,
  TdOptions,
  InputText,
  LabelText,
  SubPage,
  BtnCreateProduct,
  InputDiv,
  InputSearch,
  DivRow,
  InputContainerNumber,
  InputTextArea,
  BtnBack,
  DivVariations,
  DivOptions,
  DivImage,
  DivImageVariation,
  DropContainer,
  UploadMessage,
  SpanWithe,
  BtnCreateProductOpacity,
} from './styles';

interface IProduct {
  id: number;
  categoryId: number
  collection?: string
  description?: string
  discount?: string
  expiresIn?: string
  limitPurchase?: number
  line?: string
  name: string
  observation?: string
  price: string
  quantity?: number
}
interface IOptions {
  name: string;
  price?: string;
  quantity?: number;
}
interface IVariations {
  name: string;
  options: IOptions[];
}
interface ICurrentVariation {
  variationName?: string;
  optionName?: string;
}
interface Icategory {
  id: number;
  name: string;
  level: number;
  idSecundary?: number;
}
interface IImage {
  imageFileName: string
  imageContentType: string
  imagePath: string
  imageSize: number
  optionName?: string
  variationName?: string
  preview: string
}
type CreateProductEntry = {
  name: string
  description?: string
  limitPurchase?: number
  quantity?: number
  price: string
  expiresIn?: Date
  observation?: string
  sku?: string
  externalId?: string
  discount?: string
  line?: string
  collection?: string
  categoryId: number
  images: IImage[]
  variations?: IVariations[]
}
export const Product: React.FC = () => {
  const [viewCreateProduct, setViewCreateProduct] = useState<boolean>(false);
  const [name, setName] = useState<string>();
  const [searchKey, setSearchKey] = useState<string>();
  const [products, setProducts] = useState<IProduct[]>();
  const [price, setPrice] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [purchaseLimit, setPurchaseLimit] = useState<number>();
  const [availableQuantity, setAvailableQuantity] = useState<number>();
  const [expireIn, setExpireIn] = useState<any>();
  const [observation, setObservation] = useState<string>();
  const [sku, setSku] = useState<string>();
  const [externalId, setExternalId] = useState<string>();
  /** *
   * states of Variations
   */
  const [addVariation, setAddVariation] = useState<boolean>(false);
  const [step2, setStep2] = useState<boolean>(false);
  const [variations, setVariations] = useState<IVariations[]>();
  const [variationsName, setVariationsName] = useState<string>();
  const [optionName, setOptionName] = useState<string>();
  const [optionQuantity, setOptionQuantity] = useState<number>();
  const [optionPrice, setOptionPrice] = useState<string>();
  /** *
   * states of imagens
   */
  const [step3, setStep3] = useState<boolean>(false);
  const [imageMap, setImageMap] = useState<IImage[]>();
  const [currentVariation, setCurrentVariation] = useState<ICurrentVariation>();
  /** *
   * states of category
   */
  const [step4, setStep4] = useState<boolean>(false);
  const [categoriesLevel1, setCategoriesLevel1] = useState<Icategory[]>();
  const [category, setCategory] = useState<Icategory>();
  const [categoryFinal, setCategoryFinal] = useState<Icategory>();

  useEffect(() => {
    async function reloadList() {
      const response = await api.get('/category/level/one');
      const responseProducts = await api.get('/product?filter=');
      setCategoriesLevel1(response.data);
      setProducts(responseProducts.data);
      console.log({ product: responseProducts.data });
    }
    reloadList();
  }, []);
  useEffect(() => {
    async function reloadList() {
      const response = await api.get(`/product?filter=${searchKey}`);
      setProducts(response.data);
    }
    async function reloadListMain() {
      const response = await api.get('/product?filter=');
      setProducts(response.data);
    }
    if (searchKey && searchKey.length > 2) {
      reloadList();
    }
    if (searchKey && searchKey.length < 2) {
      reloadListMain();
    }
  }, [searchKey]);

  const toBase64 = (fileTmp: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileTmp);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  const handleDelete = async (id: number) => {
    if (window.confirm('Você realmente quer excluir esse produto?')) {
      await api.delete(`/product?id=${id}`);
      window.location.reload();
    }
  };

  const submitProduct = async () => {
    if (!categoryFinal) {
      alert('Selecione a categoria!');
      return;
    }
    if (!name) {
      alert('Nome do produto é obrigatório!');
      return;
    }
    if (!price) {
      alert('Preco do produto é obrigatório!');
      return;
    }
    if (!imageMap) {
      alert('Imagem é obrigatória!');
      return;
    }
    let limitPurchase;
    let quantity;
    if (purchaseLimit && String(purchaseLimit).trim().length > 0) {
      const formatNumber = Number(purchaseLimit);
      if (formatNumber > 0) {
        limitPurchase = formatNumber;
      }
    }
    if (availableQuantity && String(availableQuantity).trim().length > 0) {
      const formatNumber = Number(availableQuantity);
      if (formatNumber > 0) {
        quantity = formatNumber;
      }
    }
    console.log({ purchaseLimit, availableQuantity });
    const data: CreateProductEntry = {
      categoryId: categoryFinal.id,
      name,
      price,
      description,
      expiresIn: expireIn,
      observation,
      sku,
      externalId,
      limitPurchase,
      quantity,
      variations,
      images: imageMap,
    };
    try {
      const response = await api.post('/product', data);
      console.log({
        response: response.data,
      });
      window.location.reload();
    } catch (error) {
      console.log({ error });
      alert(error);
    }
  };

  const nextStep2 = () => {
    // const formatCurrency = price?.toLocaleString();
    if (!price) {
      alert('Coloque o Preço do produto');
      return;
    }
    if (!name) {
      alert('Coloque o Nome do produto');
      return;
    }

    if (expireIn) {
      const formatDate = new Date(expireIn);
      console.log({
        expireIn,
        formatDate,
        description,
        price,
        name,
        observation,
        purchaseLimit,
        availableQuantity,
      });
    }
    setStep2(true);
  };

  /** *
   * Functions of Variations
   */

  const handleValidationNameVariation = () => {
    if (!variationsName) {
      alert('Digite o nome da variação!');
      return;
    }
    const existName = variations?.find((v) => v.name === variationsName);

    if (existName) {
      alert('O nome já existe em outra variação!');
      return;
    }
    const newArr = variations ? [...variations] : [];
    const newObjct: IVariations = {
      name: variationsName,
      options: [],
    };
    newArr.push(newObjct);
    setVariations(newArr);
    setAddVariation(true);
  };
  const saveVariation = () => {
    setOptionName('');
    setOptionPrice('');
    setVariationsName(undefined);
    setOptionQuantity(0);
    setAddVariation(false);
  };
  const addOptionToVatiation = () => {
    if (!optionName) {
      alert('Digite o nome da Opção dessa variação!');
      return;
    }
    let newObjtOption: IOptions = {
      name: optionName,
    };
    if (optionPrice) {
      console.log({
        optionPrice,
        priceNumber: Number(optionPrice),
        optionQuantity,
      });
      newObjtOption = {
        ...newObjtOption,
        price: optionPrice,
      };
    }
    if (optionQuantity) {
      if (typeof optionQuantity !== 'number') {
        alert('quantidade é do tipo numero!');
        return;
      }
      if (optionQuantity > 0) {
        newObjtOption = {
          ...newObjtOption,
          quantity: optionQuantity,
        };
      }
    }
    console.log({
      newObjtOption,
    });
    if (variationsName && variations) {
      const newArr = [...variations];
      const index = variations.findIndex((v) => v.name === variationsName);
      const newOptions = newArr[index]?.options ? [...newArr[index].options] : [];
      newOptions.push(newObjtOption);
      const newObjct: IVariations = {
        name: variationsName,
        options: newOptions,
      };
      newArr[index] = newObjct;
      setVariations(newArr);
      setOptionName('');
      setOptionPrice('');
      setOptionQuantity(0);
    }
  };
  const deleteVariation = (nameVar: string) => {
    console.log(nameVar);

    if (variations) {
      const lenght = variations?.length;
      if (lenght === 1) {
        setVariationsName('');
        setAddVariation(false);
      }
      const newArr = [...variations];
      const index = variations.findIndex((v) => v.name === nameVar);
      newArr.splice(index, 1);
      console.log({
        newArr,
      });
      setVariations(newArr);
    }
  };

  /** *
   * Functions of Imagens
   */

  const handlePreview = async (files: any) => {
    const newArr = imageMap ? [...imageMap] : [];
    const preview = URL.createObjectURL(files?.[0]);
    const imgBase64 = await toBase64(files?.[0]);
    console.log({ file: files?.[0] });
    if (currentVariation) {
      const newObject: IImage = {
        imageContentType: files?.[0].type,
        imageFileName: files?.[0].name.replace('.jpeg', '').replace('.png', '').replace('jpg', ''),
        imagePath: String(imgBase64),
        imageSize: files?.[0].size,
        optionName: currentVariation.optionName,
        variationName: currentVariation.variationName,
        preview: String(preview),
        // optionName: currentVariation.optionName,
        // variationName: currentVariation.variationName,
      };
      newArr.push(newObject);
      setImageMap(newArr);
    } else {
      const newObject: IImage = {
        imageContentType: files?.[0].type,
        imageFileName: files?.[0].name.replace('.jpeg', '').replace('.png', '').replace('jpg', ''),
        imagePath: String(imgBase64),
        imageSize: files?.[0].size,
        preview: String(preview),
      };
      newArr.push(newObject);
      setImageMap(newArr);
    }
  };

  const renderDragMessage = (isDragActive: any, isDragReject: any, txt?: string) => {
    if (!isDragActive) {
      if (txt) {
        return (
          <div>
            <AiFillCamera size={20} color="#000" />
            <h1 style={{ fontSize: '9px' }}>{txt}</h1>
          </div>

        );
      }
      return (
        <AiFillCamera size={20} color="#000" />
      );
    }
    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado!</UploadMessage>;
    }
    return <UploadMessage type="success">Solte aqui ... </UploadMessage>;
  };
  const handleDeleteImage = (index: number) => {
    const newArr = imageMap ? [...imageMap] : [];
    newArr.splice(index, 1);
    setImageMap(newArr);
  };

  if (step4) {
    return (
      <Container className="wrapper">
        <Sidebar />
        <Page className="content">
          <BtnBack onClick={async () => {
            if (category?.name) {
              const response = await api.get('/category/level/one');
              setCategoriesLevel1(response.data);
              setCategory(undefined);
              setCategoryFinal(undefined);
              return;
            }
            setStep4(false);
            setStep3(true);
          }}
          >
            Voltar

          </BtnBack>
          <Title>Criar Produto 4/4</Title>
          <SubPage>
            <LabelText>
              Selecione a Categoria do Produto:
              {' '}
              {categoryFinal?.name}
            </LabelText>
            <ListUsers className="panel">
              <RepositoriesInfo className="table-responsive">
                {
                categoriesLevel1 && (
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ color: '#000' }}>Nome</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoriesLevel1?.map((cat: any) => (
                        <tr
                          onClick={async () => {
                            const response = await api.get(`/category/search?id=${cat.id}`);
                            if (response.data[0]?.name) {
                              setCategoriesLevel1(response.data);
                              setCategory(cat);
                            } else {
                              setCategoryFinal(cat);
                            }
                          }}
                          key={cat.id}
                        >
                          <td>{cat?.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )
              }
                <PaginateBar className="pagination-table">
                  <small>
                    Mostrando
                    {' '}
                    de
                    {' '}
                    Categorias
                  </small>

                </PaginateBar>
              </RepositoriesInfo>
            </ListUsers>
            {
                categoryFinal?.name && (
                  <BtnCreateProduct onClick={() => submitProduct()}>
                    <small>Criar Produto</small>
                  </BtnCreateProduct>
                )
              }
            {
              !categoryFinal?.name && (
              <BtnCreateProductOpacity onClick={() => {
                //
              }}
              >
                <small>Criar Produto</small>
              </BtnCreateProductOpacity>
              )
              }

          </SubPage>
        </Page>
      </Container>
    );
  }

  if (step3) {
    return (
      <Container className="wrapper">
        <Sidebar />
        <Page className="content">
          <BtnBack onClick={() => {
            setStep3(false);
            setStep2(true);
          }}
          >
            Voltar

          </BtnBack>
          <Title>Criar Produto 3/4</Title>
          <SubPage>
            <LabelText>Adicionar Arquivos de mídia</LabelText>

            <DivVariations>
              <h4>Você pode adicionar imagens específicas das variações e imagens  para todo o Contexto do Produto</h4>
              {
                variations && (
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ color: '#000' }}>Nome da Variação</th>
                        <th style={{ color: '#000' }}>N de Opções</th>
                        <th style={{ color: '#000' }}>Nome da Opção</th>
                        <th style={{ color: '#000' }}>Preço</th>
                        <th style={{ color: '#000' }}>Quantidade</th>
                        <th style={{ color: '#000' }}>{' '}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {variations?.map((v: any) => (
                        <>
                          <tr
                            key={v.name}
                          >
                            <td>{v?.name}</td>
                            <td>{v?.options.length}</td>
                            <td>{' '}</td>
                            <td>{' '}</td>
                            <td>{' '}</td>
                            <td>
                              {' '}
                            </td>
                          </tr>
                          {
                          v?.options.length > 0 && v?.options.map((opt: IOptions) => (
                            <tr
                              key={opt.name}
                            >
                              <td>{' '}</td>
                              <td>{' '}</td>
                              <td>{opt.name}</td>
                              <td>{opt?.price}</td>
                              <td>{opt?.quantity}</td>
                              <td>
                                {
                                  currentVariation && currentVariation.optionName === opt.name && currentVariation.variationName === v.name && (
                                    <MdPhotoSizeSelectActual
                                      onClick={() => setCurrentVariation(undefined)}
                                      color="#0bd222"
                                      size={20}
                                    />
                                  )
                                }
                                {
                                  (!currentVariation || (currentVariation.optionName !== opt.name || currentVariation.variationName !== v.name)) && (
                                    <MdOutlinePhotoSizeSelectLarge
                                      onClick={() => setCurrentVariation({
                                        optionName: opt.name,
                                        variationName: v.name,
                                      })}
                                      color="#14ace3"
                                      size={20}
                                    />
                                  )
                                }

                              </td>
                            </tr>
                          ))
                        }
                        </>
                      ))}

                    </tbody>
                  </table>
                )
              }
            </DivVariations>

            <DivImageVariation>
              {
                imageMap && imageMap.map((i, index) => (

                  <DivImage key={i.preview}>
                    <DropContainer
                      img={i.preview}
                    >
                      <DivColumnFlexSample>
                        <AiFillDelete onClick={() => handleDeleteImage(index)} size={33} color="#c45" />
                        <SpanWithe>{i.optionName}</SpanWithe>
                      </DivColumnFlexSample>

                    </DropContainer>
                  </DivImage>
                ))
              }
              <DivImage>
                {/* <AiFillCamera size={20} color="#000" /> */}
                <Dropzone
                  accept={{ 'image/*': ['png'] }}
                  onDropAccepted={handlePreview}
                  onDrop={(acceptedFiles) => console.log({ acceptedFiles })}
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragReject,
                  }) => (
                    <DropContainer
                      {...getRootProps()}
                      isDragActive={isDragActive} // aceitar img
                      isDragReject={isDragReject}
                      img=""
                    >
                      <input {...getInputProps()} />
                      {renderDragMessage(isDragActive, isDragReject, 'Aqui a imagem')}
                    </DropContainer>
                  )}
                </Dropzone>
              </DivImage>
            </DivImageVariation>
            {
                !addVariation && (
                  <BtnCreateProduct onClick={() => {
                    setStep4(true);
                    setStep3(false);
                  }}
                  >
                    <small>Próxima Etapa</small>
                  </BtnCreateProduct>
                )
              }
            {
                addVariation && (
                  <BtnCreateProduct onClick={() => saveVariation()}>
                    <small>Salvar Variação</small>
                  </BtnCreateProduct>
                )
              }
          </SubPage>
        </Page>
      </Container>
    );
  }
  if (step2) {
    return (
      <Container className="wrapper">
        <Sidebar />
        <Page className="content">
          <BtnBack onClick={() => {
            if (addVariation) {
              setAddVariation(false);
              setVariationsName(undefined);
              const index = variations?.findIndex((v) => v.name === variationsName);
              const newArr = variations ? [...variations] : [];
              if (!index) {
                return;
              }
              newArr.splice(index, 1);
            } else {
              setStep2(false);
              setViewCreateProduct(true);
            }
          }}
          >
            Voltar

          </BtnBack>
          <Title>Criar Produto 2/4</Title>
          <SubPage>
            {
              !addVariation && (
                <>
                  <LabelText>Nova Variação</LabelText>
                  <InputDiv>
                    <InputSearch
                      onChange={(t: any) => {
                        setVariationsName(t.target.value);
                      }}
                      value={variationsName}
                      type="text"
                    />
                    <AiFillPlusCircle onClick={() => handleValidationNameVariation()} size={30} color="#12e841" />
                  </InputDiv>
                </>
              )
            }
            {
              addVariation && (
                <DivOptions>
                  <LabelText>
                    Variação:
                    {' '}
                    {variationsName}
                  </LabelText>
                  <LabelText>Nome da Opção</LabelText>
                  <InputContainer>
                    <InputText
                      onChange={(t: any) => {
                        setOptionName(t.target.value);
                      }}
                      value={optionName}
                      type="text"
                    />
                  </InputContainer>

                  <LabelText>Preço da Opcão (Opcional que soma com o Preço base)</LabelText>
                  <InputContainer>
                    <InputText
                      onChange={(t: any) => {
                        const rgx = /[A-Z a-z]/g;
                        const str = String(t.target.value).replace(rgx, '');
                        setOptionPrice(str);
                      }}
                      value={optionPrice}
                      type="text"
                    />
                  </InputContainer>

                  <LabelText>Quantidade de Produtos nessa Opção (Opcional)</LabelText>
                  <InputContainerNumber>
                    <InputText
                      onChange={(t: any) => {
                        setOptionQuantity(Number(t.target.value));
                      }}
                      type="number"
                      value={optionQuantity}
                    />
                  </InputContainerNumber>

                  <AiFillPlusCircle
                    onClick={() => {
                      addOptionToVatiation();
                    }}
                    size={30}
                    color="#12e841"
                  />
                </DivOptions>
              )
            }

            <DivVariations>
              {
                variations && (
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ color: '#000' }}>Nome da Variação</th>
                        <th style={{ color: '#000' }}>N de Opções</th>
                        <th style={{ color: '#000' }}>Nome da Opção</th>
                        <th style={{ color: '#000' }}>Preço</th>
                        <th style={{ color: '#000' }}>Quantidade</th>
                        <th style={{ color: '#000' }}>{' '}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {variations?.map((v: any) => (
                        <>
                          <tr
                            key={v.name}
                          >
                            <td>{v?.name}</td>
                            <td>{v?.options.length}</td>
                            <td>{' '}</td>
                            <td>{' '}</td>
                            <td>{' '}</td>
                            <td>
                              <AiOutlineDelete onClick={() => deleteVariation(v.name)} color="#c45" size={20} />
                            </td>
                          </tr>
                          {
                          v?.options.length > 0 && v?.options.map((opt: IOptions) => (
                            <tr
                              key={opt.name}
                            >
                              <td>{' '}</td>
                              <td>{' '}</td>
                              <td>{opt.name}</td>
                              <td>{opt?.price}</td>
                              <td>{opt?.quantity}</td>
                              <td>
                                {' '}
                              </td>
                            </tr>
                          ))
                        }
                        </>
                      ))}

                    </tbody>
                  </table>
                )
              }
            </DivVariations>
            {
                !addVariation && (
                  <BtnCreateProduct onClick={() => {
                    setStep3(true);
                    setStep2(false);
                  }}
                  >
                    <small>Próxima Etapa</small>
                  </BtnCreateProduct>
                )
              }
            {
                addVariation && (
                  <BtnCreateProduct onClick={() => saveVariation()}>
                    <small>Salvar Variação</small>
                  </BtnCreateProduct>
                )
              }
          </SubPage>
        </Page>
      </Container>
    );
  }

  if (viewCreateProduct) {
    return (
      <Container className="wrapper">
        <Sidebar />
        <Page className="content">
          <Title>Novo Produto</Title>
          <SubPage>
            <LabelText>Nome do Produto</LabelText>
            <InputContainer>
              <InputText
                onChange={(t: any) => {
                  setName(t.target.value);
                }}
                type="text"
                value={name}
              />
            </InputContainer>

            <LabelText>SKU (Opcional)</LabelText>
            <InputContainer>
              <InputText
                onChange={(t: any) => {
                  setSku(t.target.value);
                }}
                type="text"
                value={sku}
              />
            </InputContainer>

            <LabelText>Id de Referência Externa (Opcional)</LabelText>
            <InputContainer>
              <InputText
                onChange={(t: any) => {
                  setExternalId(t.target.value);
                }}
                type="text"
                value={externalId}
              />
            </InputContainer>

            <LabelText>Descrição (opcional)</LabelText>
            <InputContainer>
              <InputTextArea
                onChange={(t: any) => {
                  setDescription(t.target.value);
                }}
                value={description}
              />
            </InputContainer>

            <LabelText>Limitar Compras Por pessoas (Opcional)</LabelText>
            <InputContainerNumber>
              <InputText
                onChange={(t: any) => {
                  setPurchaseLimit(t.target.value);
                }}
                type="number"
                value={purchaseLimit}
              />
            </InputContainerNumber>

            <LabelText>Definir Quantidade Disponível (Opcional)</LabelText>
            <InputContainerNumber>
              <InputText
                type="number"
                onChange={(t: any) => {
                  setAvailableQuantity(t.target.value);
                }}
                value={availableQuantity}
              />
            </InputContainerNumber>

            <LabelText>Preço Base</LabelText>
            <InputContainerNumber>
              <InputText
                onChange={(t: any) => {
                  const rgx = /[A-Z a-z]/g;
                  const str = String(t.target.value).replace(rgx, '');
                  setPrice(str);
                }}
                value={price}
              />
            </InputContainerNumber>

            <LabelText>Adicionar data de Expiração (Opcional)</LabelText>
            <InputContainer>
              <InputText
                onChange={(t: any) => {
                  setExpireIn(t.target.value);
                }}
                type="date"
                value={expireIn}
              />
            </InputContainer>

            <LabelText>Observações (Opcional)</LabelText>
            <InputContainer>
              <InputTextArea
                onChange={(t: any) => {
                  setObservation(t.target.value);
                }}
                value={observation}
              />
            </InputContainer>

            <BtnCreateProduct onClick={() => nextStep2()}>
              <small>Próxima Etapa</small>
            </BtnCreateProduct>
          </SubPage>
        </Page>
      </Container>
    );
  }

  return (
    <>
      <Container className="wrapper">
        <Sidebar />
        <Page className="content">
          <Title>Produtos</Title>
          <DivRow>
            <BtnNewCat onClick={() => { setViewCreateProduct(true); }}>Novo Produto</BtnNewCat>
            <InputDiv>
              <InputSearch
                onChange={(t: any) => {
                  setSearchKey(t.target.value);
                }}
                type="text"
                value={searchKey}
              />
              <AiOutlineSearch size={20} color="#000" />
            </InputDiv>
          </DivRow>

          <ListUsers className="panel">
            <RepositoriesInfo className="table-responsive">
              {
                products && (
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ color: '#000' }}>ID</th>
                        <th style={{ color: '#000' }}>Nome</th>
                        <th style={{ color: '#000' }}>Preço</th>
                        <th style={{ color: '#000' }}>Opções</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products?.map((p: IProduct) => (
                        <tr
                          // onClick={async () => {
                          //   generateStringIds(p.id);
                          //   generateString(p.sku);
                          //   setProducts(p);
                          // }}
                          key={p.id}
                        >
                          <td>{p?.id}</td>
                          <td>{p?.name}</td>
                          <td>{p?.price}</td>
                          <td>
                            <TdOptions>
                              <AiOutlineDelete onClick={() => handleDelete(p.id)} size={20} color="#c45" />
                              {/* <AiOutlineFolderView size={20} /> */}
                            </TdOptions>
                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                )
              }
              <PaginateBar className="pagination-table">
                <small>
                  Mostrando
                  {' '}
                  de
                  {' '}
                  Produtos
                </small>

              </PaginateBar>
            </RepositoriesInfo>
          </ListUsers>
        </Page>
      </Container>
    </>
  );
};

export default Product;
