import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    padding: 0 5px;
    background-color: #2b3040;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export const Img = styled.img`
    /* margin: 2%;
    object-fit: cover; */
    
`;
export const Button1 = styled.button`
    background-color: transparent;
    border: none;
`;
export const Button = styled.button`
    width: 85%;
    background-color: #e83c4d;
    height: 15%;
    border-radius: 10px;
    border: 0;
    margin-top: 47px;
    color: #f4ede8;
    font-weight: 500;
    transition: background-color 0.2s;
    &:hover {
        background: ${shade(0.2, '#BCF9C5')};
    }
`;
export const Div1 = styled.div`
    height: 50%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
export const InputComponent = styled.div`
    border: 1px solid #fff;
    height: 15%;
    width: 85%;
    margin-top: 10px;
    border-radius: 5px;
    padding: 17px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
export const InputChildren = styled.input`
    height: 100%;
    width: 100%;
    border: none;
`;
export const InputEmail = styled.input`
    border: 1px solid #fff;
    height: 15%;
    width: 85%;
    margin-top: 10px;
    padding: 17px;
    border-radius: 5px;
`;
