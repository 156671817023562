import React, { useState, useEffect } from 'react';
// import { FiTrash2, FiSearch } from 'react-icons/fi';

import api from '../../services/api';
import Sidebar from '../../components/sidebar';
import {
  Container,
  Page,
  Title,
  ListUsers,
  RepositoriesInfo,
  PaginateBar,
  BtnNewCat,
  InputContainer,
  // InputContainerNumber,
  InputText,
  LabelText,
  SubPage,
  BtnCreateCategory,
  CategoryText,
  BtnBack,
  DivRowFlex,
  // InputContainerSearch,
} from './styles';

interface Coordinates {
  x: number;
  y: number;
}
interface Address {
  street: string;
  street_number: string;
  zipcode: string;
  state: string;
  city: string;
  coordinates: Coordinates;
  neighborhood?: string;
  complementary?: string;
}

type IRepository = {
  name: string;
  nameGeneric: string;
  logo?: string;
  address: Address;
}[];
interface Icategory {
  id: number;
  name: string;
  level: number;
  idSecundary?: number;
}
export const Profiles: React.FC = () => {
  const [viewNewCategoryPage, setViewNewCategoryPage] = useState<boolean>(false);
  const [name, setName] = useState<string>();
  // const [searchKey, setSearchKey] = useState<string>();
  const [categoriesLevel1, setCategoriesLevel1] = useState<Icategory[]>();
  const [category, setCategory] = useState<Icategory>();
  const [categoryBackIds, setCategoryBackIds] = useState<string>();
  const [stringCat, setStringCat] = useState<string>();

  const goBack = async () => {
    let newStrIds = 'null';
    if (!categoryBackIds) {
      const response = await api.get('/category/level/one');
      setCategoriesLevel1(response.data);
      setCategory(undefined);
      setStringCat(undefined);
      return;
    }
    const newStringSplit = categoryBackIds?.split('>');
    const index = newStringSplit.length - 2;
    console.log({
      listIds: categoryBackIds,
      ultimoElement: newStringSplit[index],
    });
    if (newStringSplit[index]) {
      const responseList = await api.get(`/category/search?id=${newStringSplit[index]}`);
      const responseCat = await api.get(`/category/one?id=${newStringSplit[index]}`);
      setCategory(responseCat.data || undefined);
      setCategoriesLevel1(responseList.data);
      newStringSplit.splice(index, 1);
      newStringSplit.forEach((str: string) => {
        if (newStrIds !== 'null') {
          newStrIds = `${newStrIds}>${str}`;
        } else {
          newStrIds = str;
        }
      });
      if (newStrIds !== 'null') {
        setCategoryBackIds(newStrIds);
      } else {
        setCategoryBackIds(undefined);
      }
    } else {
      const responseList = await api.get('/category/level/one');
      setCategoriesLevel1(responseList.data);
      setCategory(undefined);
      newStringSplit.splice(index + 1, 1);
      newStringSplit.forEach((str: string) => {
        if (newStrIds !== 'null') {
          newStrIds = `${newStrIds} > ${str}`;
        } else {
          newStrIds = str;
        }
      });
      if (newStrIds !== 'null') {
        setCategoryBackIds(newStrIds);
      } else {
        setCategoryBackIds(undefined);
      }
    }

    let newStr = 'null';
    if (stringCat) {
      const newStringSplit2 = stringCat?.split('>');
      const index2 = newStringSplit2.length - 1;
      newStringSplit2.splice(index2, 1);
      newStringSplit2.forEach((str: string) => {
        if (newStr !== 'null') {
          newStr = `${newStr} > ${str}`;
        } else {
          newStr = str;
        }
      });
      if (newStr !== 'null') {
        setStringCat(newStr);
      } else {
        setStringCat(undefined);
      }
    }
  };

  const generateString = (nameStr: string) => {
    if (stringCat) {
      const newStr = `${stringCat} > ${nameStr}`;
      setStringCat(newStr);
    } else {
      const newStr = `${nameStr}`;
      setStringCat(newStr);
    }
  };

  const generateStringIds = (id: number) => {
    if (categoryBackIds) {
      const newStr = `${categoryBackIds}>${id}`;
      const newStringIdSplit = newStr.split('>');
      console.log({ newStringIdSplit });
      setCategoryBackIds(newStr);
    } else {
      const newStr = `${id}`;
      setCategoryBackIds(newStr);
    }
  };

  useEffect(() => {
    async function reloadList() {
      const response = await api.get('/category/level/one');
      setCategoriesLevel1(response.data);
    }
    reloadList();
  }, []);
  // useEffect(() => {
  //   async function reloadList() {
  //     const response = await api.get(`/category?filter=${searchKey}&idSecundary=${categoryIdCurrent}`);
  //     setCategoriesLevel1(response.data);
  //   }
  //   if (searchKey && searchKey.length > 2) {
  //     reloadList();
  //   }
  // }, [searchKey]);

  const submitCategory = async () => {
    if (!name) {
      alert('O Nome da categoria é obrigatório!');
      return;
    }
    if (category) {
      console.log({ category });
      const data: any = {
        name,
        level: 2,
        idSecundary: category.id,
      };
      const response: any = await api.post('/category', data);
      const responseList = await api.get(`/category/search?id=${category.id}`);
      if (response.data) {
        setViewNewCategoryPage(false);
        setCategoriesLevel1(responseList.data);
      }
      return;
    }
    const data: any = {
      name,
      level: 1,
    };
    const response: any = await api.post('/category', data);
    const responseList = await api.get('/category/level/one');
    setStringCat(undefined);
    setCategoriesLevel1(responseList.data);
    if (response.data) {
      setViewNewCategoryPage(false);
    }
  };

  if (viewNewCategoryPage) {
    return (
      <Container className="wrapper">
        <Sidebar />
        <Page className="content">
          <Title>Categorias</Title>
          <SubPage>
            <LabelText>Nome da Categoria:</LabelText>
            <InputContainer>
              <InputText
                onChange={(t: any) => {
                  setName(t.target.value);
                }}
                type="text"
              />
            </InputContainer>

            {/* <LabelText>Nível da Categoria:</LabelText>
            <InputContainerNumber>
              <InputText
                onChange={(t: any) => {
                  setLevel(Number(t.target.value));
                }}
                placeholder="de 1 - 9"
                type="number"
                min={1}
                max={9}
              />
            </InputContainerNumber> */}

            <BtnCreateCategory onClick={() => submitCategory()}>
              <small>Criar Categoria</small>
            </BtnCreateCategory>
          </SubPage>
        </Page>
      </Container>
    );
  }

  if (category) {
    return (
      <>
        <Container className="wrapper">
          <Sidebar />
          <Page className="content">
            <Title>
              Sub Categorias
            </Title>
            <DivRowFlex>
              <BtnBack onClick={() => { goBack(); }}>Voltar</BtnBack>
              <BtnNewCat onClick={() => { setViewNewCategoryPage(true); }}>Nova SubCategoria</BtnNewCat>
            </DivRowFlex>

            <CategoryText>{stringCat}</CategoryText>
            <ListUsers className="panel">
              <RepositoriesInfo className="table-responsive">
                {
                categoriesLevel1 && (
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ color: '#000' }}>Nome</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoriesLevel1?.map((cat: any) => (
                        <tr
                          style={{ borderColor: '#000', backgroundColor: '#fff', borderBottom: '#000' }}
                          onClick={async () => {
                            // if (categoryBackId) {
                            //   const newArr = [...categoryBackId];
                            //   newArr.push(category.id);
                            //   console.log({
                            //     newArr,
                            //   });
                            //   setCategoryBackId(newArr);
                            // } else {
                            //   const newArr = [category.id];
                            //   setCategoryBackId(newArr);
                            // }
                            generateStringIds(cat.id);
                            generateString(cat.name);
                            const response = await api.get(`/category/search?id=${cat.id}`);
                            setCategoriesLevel1(response.data);
                            setCategory(cat);
                          }}
                          key={cat.id}
                        >
                          <td>{cat?.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )
              }
                <PaginateBar className="pagination-table">
                  <small>
                    Mostrando
                    {' '}
                    de
                    {' '}
                    Categorias
                  </small>

                </PaginateBar>
              </RepositoriesInfo>
            </ListUsers>
          </Page>
        </Container>
      </>
    );
  }

  return (
    <>
      <Container className="wrapper">
        <Sidebar />
        <Page className="content">
          <Title>Categorias</Title>
          <BtnNewCat onClick={() => { setViewNewCategoryPage(true); }}>Nova Categoria</BtnNewCat>
          <ListUsers className="panel">
            <RepositoriesInfo className="table-responsive">
              {
                categoriesLevel1 && (
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ color: '#000' }}>Nome</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoriesLevel1?.map((cat: any) => (
                        <tr
                          onClick={async () => {
                            generateStringIds(cat.id);
                            generateString(cat.name);
                            const response = await api.get(`/category/search?id=${cat.id}`);
                            setCategoriesLevel1(response.data);
                            setCategory(cat);
                          }}
                          key={cat.id}
                        >
                          <td>{cat?.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )
              }
              <PaginateBar className="pagination-table">
                <small>
                  Mostrando
                  {' '}
                  de
                  {' '}
                  Categorias
                </small>

              </PaginateBar>
            </RepositoriesInfo>
          </ListUsers>
        </Page>
      </Container>
    </>
  );
};

export default Profiles;
