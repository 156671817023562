import React, {
  useState,
} from 'react';
import { GoEyeClosed, GoEye } from 'react-icons/go';

import logoLogin from '../../assets/logo.png';
import { useAuth } from '../../hooks/auth';
import {
  Container,
  Img,
  Button,
  Button1,
  Div1,
  InputChildren,
  InputComponent,
  InputEmail,
} from './styles';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [eyeClosed, setEyeClosed] = useState(true);

  const { login } = useAuth();

  const handleSubmitLogin = () => {
    login(email, password);
  };
  const handleVisiblePass = () => {
    setEyeClosed(!eyeClosed);
  };
  return (
    <Container>
      <Img
        src={logoLogin}
        style={{
          width: 250, height: 250, objectFit: 'contain', resize: 'horizontal',
        }}
      />
      <Div1>
        <InputEmail
          name="email"
          placeholder="E-mail:"
          autoCapitalize="off"
          onChange={(event: any) => setEmail(event.target.value)}
          value={email}
        />
        {
            eyeClosed && (
              <InputComponent>
                <InputChildren
                  name="password"
                  type="password"
                  placeholder="Senha :"
                  autoCapitalize="off"
                  onChange={(event: any) => setPassword(event.target.value)}
                  value={password}
                />
                <Button1 type="button" onClick={() => handleVisiblePass()}>
                  <GoEye size={17} color="#000" />
                </Button1>
              </InputComponent>

            )
        }
        {
            !eyeClosed && (
              <InputComponent>
                <InputChildren
                  name="password"
                  type="text"
                  placeholder="Senha :"
                  autoCapitalize="off"
                  onChange={(event: any) => setPassword(event.target.value)}
                  value={password}
                />
                <Button1 type="button" onClick={() => handleVisiblePass()}>
                  <GoEyeClosed size={17} color="#000" />
                </Button1>
              </InputComponent>
            )
        }
        <Button onClick={handleSubmitLogin}>Entrar</Button>
      </Div1>

    </Container>

  );
};

export default Login;
