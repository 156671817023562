import styled from 'styled-components';

interface Props {
  isDragActive?: any;
  dragActive?: any;
  dragReject?: any;
  isDragReject?: any;
  img?: any;
}
interface Type {
  type?: 'default' | 'error' | 'success';
}
export const Container = styled.div`
  display: flex;
  div {
    width: 100%;
  }
`;
export const Page = styled.div`
  width: 100%;
`;
export const SubPage = styled.div`
  min-width: 95%;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
`;
export const Title = styled.h3`
  top: 0;
  position: absolute;
  color: #fff;
  font-size: 22px;
  text-align: center;
  font-weight: bolder;
  background-color: #2b3040;
  width: 90%;
  padding: 20px;
`;
export const PaginateBar = styled.div``;

export const AddMall = styled.div`
  justify-content: center;
  align-items: center;

  background-color: #fff;
  padding: 1%;
  input {
    border-radius: 7px;
    border: 2px solid #f0f0f0;
    width: 77%;
    margin-left: 10%;
    height: 27px;
    padding-left: 30%;
    margin-bottom: 0;
  }
  div {
    display: flex;
    margin-left: 4px;
  }
  label {
    margin: 1%;
  }
  form {
    display: flex;
    width: 100%;
    div {
      display: block;
      input {
        border-radius: 7px;
        border: 2px solid #f0f0f0;
        padding-left: 10%;
        width: 100%;
        margin-left: 0%;
      }
    }
  }
  button {
    margin-left: 40%;
  }
`;

export const MapStyle = styled.div`
  
`;

export const ListUsers = styled.div`
  margin-top: 3%;
  background-color: #f3f3f7;
`;
export const RepositoriesInfo = styled.div`
  width: 100%;
  padding: 2%;
  table {
    width: 100%;
    thead {
      tr {
        th {
          color: #f3f3f7;
          font-family: 'Roboto', sans-serif;
          font-size: 12px;
          & + th {
            background-color: #fff;
            border: 1px solid #f0f0f0;
            color: #151715;
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding-left: 8px;
          padding-right: 1px;
          margin-right: 0px;
          height: 100%;
          max-width: 5px;
          background-color: #f3f3f7;
          button {
            margin-left: 27%;
            border: none;
            background-color: #fff;
            & + button {
              margin-left: 5%;
            }
          }
          & + td {
            padding: 1px;
            height: 32px;
            margin: 0;
            max-width: none;
            padding: 1px;
            background-color: #fff;
            border: 1px solid #f0f0f0;
            font-size: 12px;
          }
        }
      }
    }
  }
`;

export const Logo = styled.img`
  max-width: 45%;
  margin-left: 7px;
  height: 2%;
  border-radius: 50%;
`;
export const BtnNewCat = styled.div`
  padding: 20px;
  background-color: #27e51a;
  max-width: 200px;
  min-width: 200px;
  display: flex;
  border-radius: 7px;
  color: #fff;
  align-items: center;
  justify-content: center;
`;

export const LabelText = styled.h2`
  font-size: 14px;
  color: #000;
  margin: 10px;
`;
export const ProductText = styled.h2`
  font-size: 14px;
  color: #000;
  width: 77%;
  margin-left: auto;
  margin-right: auto;
`;
export const InputText = styled.input`
  max-width: 100%;
  border: none;
  min-width: 100%;
  min-height: 30px;
  max-height: 30px;
`;
export const InputTextArea = styled.textarea`
  max-width: 100%;
  border: none;
  min-width: 100%;
  min-height: 60px;
  max-height: 60px;
`;
export const InputContainer = styled.div`
  max-width: 400px;
  min-width: 400px;
  min-height: 50px;
  padding: 7px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  margin: 20px;
`;
export const InputContainerSearch = styled.div`
  max-width: 400px;
  min-width: 400px;
  max-height: 50px;
  min-height: 50px;
  padding: 7px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: none;
  border-radius: 12px;
  margin: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const InputContainerNumber = styled.div`
  max-width: 100px;
  min-width: 100px;
  max-height: 50px;
  min-height: 50px;
  padding: 7px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  margin: 20px;
`;

export const BtnCreateProduct = styled.button`
  padding: 12px;
  background-color: #1B7D84;
  margin: 17px;
  max-width: 200px;
  min-width: 200px;
  display: flex;
  border-radius: 22px;
  color: #fff;
  align-items: center;
  justify-content: center;
`;
export const BtnCreateProductOpacity = styled.button`
  padding: 12px;
  opacity: 0.7;
  background-color: #1B7D84;
  margin: 17px;
  max-width: 200px;
  min-width: 200px;
  display: flex;
  border-radius: 22px;
  color: #fff;
  align-items: center;
  justify-content: center;
`;
export const BtnBack = styled.button`
  padding: 12px;
  background-color: transparent;
  max-width: 100px;
  min-width: 100px;
  display: flex;
  border-radius: 22px;
  color: #000;
  align-items: center;
  justify-content: center;
`;

export const DivRowFlex = styled.div`
  background-color: transparent;
  max-width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
`;
export const DivColumnFlexSample = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const SpanWithe = styled.span`
  color: #fff;
  font-weight: bold;
  font-size: 12px;
`;
export const TdOptions = styled.div`
  padding: 1px 20%;
  margin: 0;
  max-width: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const InputDiv = styled.div`
  max-width: 400px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #000;
  border-radius: 22px;
`;
export const InputSearch = styled.input`
  padding: 1px;
  min-width: 80%;
  height: 30px;
  max-width: 100%;
  border: none;
  background-color: transparent;
`;
export const DivRow = styled.div`
  display: flex;
  min-width: 100%;
  max-width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DivVariations = styled.div`
  margin: 70px;
  max-width: 70%;
`;

export const DivOptions = styled.div`
  margin: 70px;
  max-width: 60%;
  padding: 20px;
  background-color: #B0A9A9;
`;

export const DivImageVariation = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  border: none;
  border-radius: 12px;
  background-color: #676767;
`;
export const DivImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
  max-width: 100px;
  min-width: 100px;
  max-height: 100px;
  min-height: 100px;
  border: none;
  border-radius: 7px;
  background-color: transparent;
`;

export const DropContainer = styled.div<Props>`
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 8px;
  ${(props) => props.isDragActive && props.dragActive};
  ${(props) => props.isDragReject && props.dragReject};
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #f8f8f8;
  &:hover {
    background-color: #fff;
  }
`;
const messageResult = {
  default: '#212E63',
  error: '#FC4F4F',
  success: '#14C87C',
};
export const UploadMessage = styled.p<Type>`
  display: flex;
  color: ${(props) => messageResult[props.type || 'default']};
  justify-content: center;
  align-items: center;
  padding: 32px;
`;
export default UploadMessage;
