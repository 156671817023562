import styled from 'styled-components';
// import { shade } from 'polished';

interface FormProps {
    hasError: boolean;
}

export const Container = styled.div`
    display: flex;
    div {
        width: 100%;
    }
`;
export const Page = styled.div`
    
    
`;
