import React, { useState, useEffect } from 'react';
import { FiTrash2 } from 'react-icons/fi';

import api from '../../services/api';
import Sidebar from '../../components/sidebar';
import {
  Container,
  Page,
  Title,
  ListUsers,
  RepositoriesInfo,
  PaginateBar,
} from './styles';

interface Coordinates {
  x: number;
  y: number;
}
interface Address {
  street: string;
  street_number: string;
  zipcode: string;
  state: string;
  city: string;
  coordinates: Coordinates;
  neighborhood?: string;
  complementary?: string;
}

type IRepository = {
  name: string;
  nameGeneric: string;
  logo?: string;
  address: Address;
}[];

export const Profiles: React.FC = () => {
  const [list, setList] = useState<IRepository>([]);
  const [dataPagination, setDataPagination] = useState<any>();
  const [b2, setB2] = useState<number>(2);
  const [b3, setB3] = useState<number>(3);
  const [b4, setB4] = useState<number>(4);
  const [b5, setB5] = useState<number>(5);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    async function reloadList() {
      const response = await api.get('/user', {
        params: {
          page,
        },
      });
      const totalDocs = {
        totalDocs: response.data.totalDocs,
        offset: response.data.offset,
        limit: response.data.limit,
        totalPages: response.data.totalPages,
        page: response.data.page,
        pagingCounter: response.data.pagingCounter,
        hasPrevPage: response.data.hasPrevPage,
        hasNextPage: response.data.hasNextPage,
        prevPage: response.data.prevPage,
        nextPage: response.data.nextPage,
      };
      setDataPagination(totalDocs);
      setList(response.data.docs);
    }
    reloadList();
  }, [page]);
  const handleDelete = async (id: string) => {
    if (window.confirm('Você realmente quer excluir esse usuario?')) {
      await api.delete(`/user/${id}`);
      window.location.reload();
    }
  };
  const paginationButton = (num: number) => {
    setPage(num);
    setB2(num + 1);
    setB3(num + 2);
    setB4(num + 3);
    setB5(num + 4);
  };

  return (
    <>
      <Container className="wrapper">
        <Sidebar />
        <Page className="content">
          <Title>Looks</Title>
          <ListUsers className="panel">
            <RepositoriesInfo className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ color: '#000' }}>Email</th>
                    <th>Nome</th>
                    <th>Tipo</th>
                    <th>Opcoes</th>
                  </tr>
                </thead>
                <tbody>
                  {list?.map((user: any) => (
                    <tr key={user._id}>
                      <td>{user?.email}</td>
                      <td>{user?.name}</td>
                      <td>{user?.type}</td>
                      <td>
                        <button
                          type="button"
                          onClick={() => handleDelete(user._id)}
                        >
                          <FiTrash2 size={16} color="black" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <PaginateBar className="pagination-table">
                <small>
                  Mostrando
                  {' '}
                  {dataPagination?.page}
                  /
                  {dataPagination?.totalPages}
                  {' '}
                  de
                  {' '}
                  {dataPagination?.totalDocs}
                  {' '}
                  Usuarios
                </small>
                <nav>
                  {
                      dataPagination?.hasPrevPage && <button type="button" onClick={() => paginationButton(page - 1)}>Anterior</button>
                    }
                  {
                      dataPagination?.hasNextPage
                      && (
                      <>
                        <button type="button" onClick={() => paginationButton(b2)}>{b2}</button>
                        <button type="button" onClick={() => paginationButton(b3)}>{b3}</button>
                        <button type="button" onClick={() => paginationButton(b4)}>{b4}</button>
                        <button type="button" onClick={() => paginationButton(b5)}>{b5}</button>
                        <button type="button" onClick={() => paginationButton(page + 1)}>Proximo</button>
                      </>
                      )
                    }
                </nav>
              </PaginateBar>
            </RepositoriesInfo>
          </ListUsers>
        </Page>
      </Container>
    </>
  );
};

export default Profiles;
