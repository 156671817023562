import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { useAuth } from '../hooks/auth';
import Dashboard from '../pages/Dashboard';
import Users from '../pages/Users';
import Vitrine from '../pages/Vitrines';
import Product from '../pages/Products';
import Categories from '../pages/Categories';
import Login from '../pages/Login';
import Looks from '../pages/Looks';

const RoutesComponent: React.FC = () => {
  const { signed } = useAuth();
  return (
    <>
      {
            signed && (

            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/users" element={<Users />} />
              <Route path="/looks" element={<Looks />} />
              <Route path="/pots" element={<Vitrine />} />
              <Route path="/products" element={<Product />} />
              <Route path="/categories" element={<Categories />} />

            </Routes>
            )
        }
      {
            !signed && (
              <Routes>
                <Route path="/" element={<Login />} />
              </Routes>
            )
        }
    </>
  );
};

export default RoutesComponent;
